import { useState, useEffect, useCallback } from 'react';
import { fetchPaymentsByScac } from '../services/paymentService';

function usePayments(ScacCode) {
  const [payments, setPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadPayments = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await fetchPaymentsByScac();
      if (data.success) {
        setPayments(data.PaymentResponses);
      } else {
        setError(data.message || 'Failed to fetch payments');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [ScacCode]);

  useEffect(() => {
    if (ScacCode) {
      loadPayments();
    }
  }, [ScacCode, loadPayments]);

  const refreshPayments = () => {
    loadPayments();
  };

  const onSelectPayment = (payment, type) => {
    setSelectedPayment({ ...payment, type });
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return { payments, selectedPayment, isModalOpen, onSelectPayment, onCloseModal, refreshPayments, isLoading, error };
}

export default usePayments;