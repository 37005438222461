import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import PaymentsTable from "./components/PaymentsTable/PaymentsTable";
import usePayments from "./Hooks/usePayments";
import PaymentModal from "./components/PaymentModal/PaymentModal";
import InvoiceModal from "./components/InvoiceModal/InvoiceModal";
import SnackbarInfo from "./components/SnackBar/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { FormControlLabel, Switch, Grid, useMediaQuery } from "@mui/material";
import ScacCodeSelector from "./components/ScacCodeSelector/ScacCodeSelector";
import { useTheme } from "@mui/material/styles";
import "./App.css";

export default function Root(props) {
  const [scacCode, setScacCode] = useState();
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isAuditModalOpen, setIsAuditModalOpen] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, color: '', message: '' });
  const [showCreatedOnly, setShowCreatedOnly] = useState(false);

  const {
    payments,
    isLoading,
    error,
    refreshPayments
  } = usePayments(scacCode);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    let filtered = payments;
    if (scacCode) {
      filtered = payments.filter(payment => payment.scac === scacCode);
    }
    if (showCreatedOnly) {
      filtered = filtered.filter(payment => payment.Status !== 'completed');
    }
    setFilteredPayments(filtered);
  }, [scacCode, showCreatedOnly, payments]);

  const handleScacCodeChange = (event) => {
    setScacCode(event.target.value);
  };

  const handleSelectPayment = (payment, modalType) => {
    setSelectedPayment(payment);
    if (modalType === 'invoice') {
      setIsInvoiceModalOpen(true);
    } else {
      setIsAuditModalOpen(true);
    }
  };

  const handleSwitchChange = (event) => {
    setShowCreatedOnly(event.target.checked);
  };

  const handleCloseModal = () => {
    setIsAuditModalOpen(false);
    setIsInvoiceModalOpen(false);
    setSelectedPayment(null);
  };

  const showSnackbar = (color, message) => {
    setSnackbar({ open: true, color, message });
    setTimeout(() => setSnackbar({ open: false, color: '', message: '' }), 3000);
  };

  return (
    <section id="app-admin-invoicesList">
      <div className="container" id="invoices-filters-controls">
        <div className="row">
          <div className="col-sm">
            <ScacCodeSelector
              scacCode={scacCode}
              onScacCodeChange={handleScacCodeChange}
            />
          </div>
        </div>
      </div>
      <Paper id="table-invoices" style={{ margin: 'auto', maxWidth: '100%', overflowX: 'auto' }}>
        <Typography id="titleTable-invoces">
          <Grid container spacing={3} alignItems="center">
            
          <Grid item xs={isMobile ? 12 : 4} style={{ textAlign:  'left' }}/>
            <Grid item xs={isMobile ? 12 : 4} style={{ textAlign:  'center' }}>
              Invoices
            </Grid>
            <Grid item xs={isMobile ? 12 : 4} style={{ textAlign: 'right' }}>
              <FormControlLabel
                control={
                  <Switch
                    className="custom-switch"
                    checked={showCreatedOnly}
                    onChange={handleSwitchChange}
                    color="primary"
                  />
                }
                label="Hide Paids"
              />
            </Grid>
          </Grid>
        </Typography>
        {isLoading ? (
          <div className="loading-container">
            <CircularProgress />
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <PaymentsTable
            payments={filteredPayments}
            onSelectPayment={handleSelectPayment}
          />
        )}
        {selectedPayment && (
          <PaymentModal
            payment={selectedPayment}
            open={isAuditModalOpen}
            onClose={handleCloseModal}
            showSnackbar={showSnackbar}
            refreshPayments={refreshPayments}
          />
        )}
        {selectedPayment && (
          <InvoiceModal
            open={isInvoiceModalOpen}
            payment={selectedPayment}
            onClose={handleCloseModal}
          />
        )}
      </Paper>
      {snackbar.open && (
        <SnackbarInfo color={snackbar.color} message={snackbar.message} />
      )}
    </section>
  );
}
